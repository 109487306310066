import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import pageImage from '../static/images/shop.jpeg' 

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto", padding: '2rem 0'},
    pageName:{fontSize:'3rem', textAlign:'center', fontWeight:'bolder', padding:'3rem 0', background:'#2a2c5d', color: 'white', borderTop:"5px double #ff654c", borderBottom:"5px solid #ff654c"},
    pageText:{fontSize: '1.25rem', color:'#8d8d95', lineHeight:'2.5rem', textAlign: 'justify'}
}));

const Shop = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h1" className={classes.pageName}>Shop Insurance</Typography>
            <Grid container >
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{margin:'auto', textAlign: 'center'}}><img src={pageImage} alt="" style={{maxHeight: '400px', maxWidth: "98%"}} /></Grid>
                    <Grid item xs={12} md={6} style={{padding: '2.5rem', display:'flex'}} >
                        <Typography className={classes.pageText}>A shop insurance is a type of insurance policy designed to cover for a shop’s property and its contents within. At Digit, our shop insurance covers for fire and natural calamities such as floods and earthquakes through our Standard Fire & Special Perils policy. However, since properties like shops are always at a risk of burglary, we also offer a burglary policy as part of our shop insurance. This way, your shop isn’t only protected from fires and natural calamities but also from damages and losses that could occur due to a burglary.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Shop;