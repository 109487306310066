import React from 'react';
import '../static/css/styling.css';

import { Router, Route, Switch } from 'react-router-dom';
import history from '../utils/history';

import Header from './utils/header';
import Footer from './utils/footer';
import Welcome from './welcome';
import TwoWheeler from './twoWheeler';
import CommercialVehicle from './commercialVehicle';
import Car from './car';
import Home from './home';
import Office from './office';
import Shop from './shop';
import Credit from './credit';
import Liability from './liability';
import PUC from './puc';

function App() {
  return (
    <Router history={history}>
      <Header/>
      <Switch>
          <Route path="/" exact component={Welcome} />
          <Route path="/two-wheeler-insurance" exact component={TwoWheeler} />
          <Route path="/commercial-vehicle-insurance" exact component={CommercialVehicle} />
          <Route path="/car-insurance" exact component={Car} />
          <Route path="/home-insurance" exact component={Home} />
          <Route path="/office-insurance" exact component={Office} />
          <Route path="/shop-insurance" exact component={Shop} />
          <Route path="/credit-insurance" exact component={Credit} />
          <Route path="/liability-insurance" exact component={Liability} />
          <Route path="/pollution-certificate" exact component={PUC} />
          <Route path="*" component={() => "Page not found"} />
      </Switch>
      <Footer/> 
    </Router>
  );
}

export default App;
