import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto"},
    colHeading:{fontWeight:"bolder", paddingBottom: "10px"},
    linked:{textDecoration: 'none', padding: "40px 0"},
    footerLinkButton:{color: "#606060", textTransform: 'capitalize', display: 'block', padding: 0}
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12} md={4} style={{"background":"#f2940f", "padding":"20px 10px", "textAlign":"center"}}>
            <MailIcon style={{ fontSize: 60, paddingBottom: "10px", color: "#2A2C5D" }} className={classes.callIcon} />
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"white", "fontSize":"1rem", "fontWeight":"bolder", "letterSpacing":"2px"}} >MAIL</Typography>
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"#2A2C5D", "fontSize":"1.75rem", "fontWeight":"bolder"}}><a href="mailto:sales@mahavirinsurance.in" style={{color:'white'}} target="_blank" rel="noreferrer">sales@mahavirinsurance.in</a></Typography>
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"white", "fontSize":"1rem", "fontWeight":"bolder", "letterSpacing":"2px"}} >WRITE TO US</Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{"background":"#2A2C5D", "padding":"20px 10px", "textAlign":"center"}}>
                <CallIcon style={{ fontSize: 60, paddingBottom: "10px", color: "#FF654C" }} className={classes.callIcon} />
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"white", "fontSize":"1rem", "fontWeight":"bolder", "letterSpacing":"2px"}} >CALL / WHATSAPP</Typography>
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"#FF654C", "fontSize":"2.5rem", "fontWeight":"bolder"}} ><a href="tel:+919978904008" style={{color:'white'}} target="_blank" rel="noreferrer">9978904008</a></Typography>
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"white", "fontSize":"1rem", "fontWeight":"bolder", "letterSpacing":"2px"}} >CONTACT US</Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{"background":"#f2940f", "padding":"20px 10px", "textAlign":"center"}}>
                <LocationOnIcon style={{ fontSize: 60, paddingBottom: "10px", color: "#2A2C5D" }} className={classes.callIcon} />
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"white", "fontSize":"1rem", "fontWeight":"bolder", "letterSpacing":"2px"}} >ADDRESS</Typography>
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"#2A2C5D", "fontSize":"1.25rem", "fontWeight":"bolder"}} >Anjani-2, Richa Avenue, Plot No 205, Office No 5, Second Floor, Ward : 12/B, Old Police Station Road, Gandhidham, Kutch - 370201</Typography>
                <Typography style={{"maxWidth":"500px", "margin":"auto", "color":"white", "fontSize":"1rem", "fontWeight":"bolder", "letterSpacing":"2px"}} >REACH US</Typography>
            </Grid>
            <Grid item xs={12} style={{"background":"#2A2C5D", "padding":"0px 10px", "textAlign":"center", borderTop: "2px double #f2940f"}}>
                <TwitterIcon style={{ fontSize: 30, padding: "10px", color: "#f2940f" }} className={classes.callIcon} />
                <FacebookIcon style={{ fontSize: 30, padding: "10px", color: "#f2940f" }} className={classes.callIcon} />
                <a href="https://www.instagram.com/mahavirinsurance" target='_blank'><InstagramIcon style={{ fontSize: 30, padding: "10px", color: "#f2940f" }} className={classes.callIcon} /></a>
            </Grid>
        </Grid>
    );
}

export default Footer;