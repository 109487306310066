import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import pageImage from '../static/images/credit.jpeg' 

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto", padding: '2rem 0'},
    pageName:{fontSize:'3rem', textAlign:'center', fontWeight:'bolder', padding:'3rem 0', background:'#2a2c5d', color: 'white', borderTop:"5px double #ff654c", borderBottom:"5px solid #ff654c"},
    pageText:{fontSize: '1.25rem', color:'#8d8d95', lineHeight:'2.5rem', textAlign: 'justify'}
}));

const Credit = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h1" className={classes.pageName}>Credit Insurance</Typography>
            <Grid container >
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{margin:'auto', textAlign: 'center'}}><img src={pageImage} alt="" style={{maxHeight: '400px', maxWidth: "98%"}} /></Grid>
                    <Grid item xs={12} md={6} style={{padding: '2.5rem', display:'flex'}} >
                        <Typography className={classes.pageText}>Credit Insurance is a type of insurance policy that is used to pay off existing debts in cases such as death, disability and in some cases, unemployment. Credit insurance protects the policyholder from the lender from the borrower's inability to repay the loan or debt due to various reasons. The cost of a Credit Insurance policy depends on various factors such as the loan amount/ debt amount, type of credit and the type of policy. The premiums can be paid either in a Single payment method or through the Monthly Outstanding Balance method.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Credit;