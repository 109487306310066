import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import pageImage from '../static/images/liability.jpeg' 

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto", padding: '2rem 0'},
    pageName:{fontSize:'3rem', textAlign:'center', fontWeight:'bolder', padding:'3rem 0', background:'#2a2c5d', color: 'white', borderTop:"5px double #ff654c", borderBottom:"5px solid #ff654c"},
    pageText:{fontSize: '1.25rem', color:'#8d8d95', lineHeight:'2.5rem', textAlign: 'justify'}
}));

const Liability = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h1" className={classes.pageName}>Liability Insurance</Typography>
            <Grid container >
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{margin:'auto', textAlign: 'center'}}><img src={pageImage} alt="" style={{maxHeight: '400px', maxWidth: "98%"}} /></Grid>
                    <Grid item xs={12} md={6} style={{padding: '2.5rem', display:'flex'}} >
                        <Typography className={classes.pageText}>Liability insurance is a policy that offers protection to businesses and individuals from risk that they may be held legally or sued for negligence, malpractice or injury. This insurance policy protects the insured from legal payouts and costs for which the policyholder is deemed to be responsible. The necessity for insurance today is paramount and this is due to the fact that we live in an economically uncertain world and one never knows when financial help is required. Insurance acts as a safety blanket and protects customers from various issues that may arise. Insurance plans are of various types based on the requirement.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Liability;