import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import pageImage from '../static/images/office.jpeg' 

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto", padding: '2rem 0'},
    pageName:{fontSize:'3rem', textAlign:'center', fontWeight:'bolder', padding:'3rem 0', background:'#2a2c5d', color: 'white', borderTop:"5px double #ff654c", borderBottom:"5px solid #ff654c"},
    pageText:{fontSize: '1.25rem', color:'#8d8d95', lineHeight:'2.5rem', textAlign: 'justify'}
}));

const Office = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h1" className={classes.pageName}>Office Insurance</Typography>
            <Grid container >
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{margin:'auto', textAlign: 'center'}}><img src={pageImage} alt="" style={{maxHeight: '400px', maxWidth: "98%"}} /></Grid>
                    <Grid item xs={12} md={6} style={{padding: '2.5rem', display:'flex'}} >
                        <Typography className={classes.pageText}>Office insurance is primarily focused on businesses that are run from a commercial property. Specialist policies are designed to account for the risks of your specific industry. The basic types of cover we talked about above are standard and/or legal requirements across all offices. They all have a premises, products or services and customers. This makes building the basics of your policy quite straightforward. Having said this, no two offices are the same. Understanding your specific risks and tailoring a policy to meet those is the job of a specialist insurer, and vital for your piece of mind. At the bottom of this guide you can also browse our related products section if you are unsure where you fall.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Office;