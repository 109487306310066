import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import pageImage from '../static/images/puc.jpg' 

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto", padding: '2rem 0'},
    pageName:{fontSize:'3rem', textAlign:'center', fontWeight:'bolder', padding:'3rem 0', background:'#2a2c5d', color: 'white', borderTop:"5px double #ff654c", borderBottom:"5px solid #ff654c"},
    pageText:{fontSize: '1.25rem', color:'#8d8d95', lineHeight:'2.5rem', textAlign: 'justify'}
}));

const PUC = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h1" className={classes.pageName}>Pollution Certificate</Typography>
            <Grid container >
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{margin:'auto', textAlign: 'center'}}><img src={pageImage} alt="" style={{maxHeight: '400px', maxWidth: "98%"}} /></Grid>
                    <Grid item xs={12} md={6} style={{padding: '2.5rem', display:'flex'}} >
                        <Typography className={classes.pageText}>A PUC Certificate, also known as a Pollution Under Control Certificate or a Pollution Test Certificate, is a document of approval issued by the government, which is awarded to your vehicle when the emission levels of your vehicle are recorded and found to be in compliance with the authorised emission standards. As per the guidelines issued by the Ministry of Transport, under the Central Motor Vehicles Act, 1989, a valid vehicle pollution certificate must be present with the driver at all times when the vehicle is on the road. Absence of a PUC certificate of vehicle will warrant heavy penalties for the driver and the owner of the vehicle.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default PUC;