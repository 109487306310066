import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import CallIcon from '@material-ui/icons/Call';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../static/images/logo.jpg'

const useStyles = makeStyles(theme => ({
    list: {width: 250},
    page:{maxWidth: '1200px', margin: "auto"},
    header:{backgroundColor: 'white', padding:"5px 0px"},
    drawerSubMenuHeading:{background:'#2a2c5d', color: 'white', borderRadius: 0},
    menuBar: {textAlign:'center'},
    linked:{textDecoration:'none'},
    menuBarLinkButton:{ color: "#cc1a17", borderRadius: 0, width: 'auto', fontWeight: 'bolder', paddingLeft: "15px", paddingRight: "15px" },
    subMenuBarLinkButton:{color:'blue'},
    callIcon:{color: "#ff654c", fontSize: "40px"}
}));

const Header = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const [vehicleMenu, setVehicleMenu] = React.useState(null);
    const handleVehicleClick = (event) => {setVehicleMenu(event.currentTarget); };
    const handleVehicleClose = () => { setVehicleMenu(null); };

    const [propertyMenu, setPropertyMenu] = React.useState(null);
    const handlePropertyClick = (event) => {setPropertyMenu(event.currentTarget); };
    const handlePropertyClose = () => { setPropertyMenu(null); };

    const [otherMenu, setOtherMenu] = React.useState(null);
    const handleOtherClick = (event) => {setOtherMenu(event.currentTarget); };
    const handleOtherClose = () => { setOtherMenu(null); };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setOpen(open);
    };

    const list = () => (
        <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} >
          <Link to="/two-wheeler-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Home</Button></Link>
          <Divider />
          <Button fullWidth className={classes.drawerSubMenuHeading}>Vehicle Insurance</Button>
          <Link to="/two-wheeler-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Two-Wheeler Insurance</Button></Link>
          <Link to="/commercial-vehicle-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Commercial Vehicle Insurance</Button></Link>
          <Link to="/car-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Car Insurance</Button></Link>
          <Divider />
          <Button fullWidth className={classes.drawerSubMenuHeading} >Property Insurance</Button>
          <Link to="/home-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Home Insurance</Button></Link>
          <Link to="/office-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Office Insurance</Button></Link>
          <Link to="/shop-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Shop Insurance</Button></Link>
          <Divider />
          <Button fullWidth className={classes.drawerSubMenuHeading}>Other</Button>
          <Link to="/credit-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Credit Insurance</Button></Link>
          <Link to="/liability-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Liability Insurance</Button></Link>
          <Link to="/pollution-certificate" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Pollution Certificate</Button></Link>
        </div>
      );
    
    return (
        <Grid className={classes.header}>
            <Grid container className={classes.page}>
                <Hidden lgUp>
                    <Grid item xs={2} md={1} style={{paddingTop:"10px"}}>
                        <React.Fragment>
                            <Button onClick={toggleDrawer(true)}><MenuIcon /></Button>
                            <Drawer open={open} onClose={toggleDrawer(false)}>
                                {list()}
                            </Drawer>
                        </React.Fragment>
                    </Grid>
                </Hidden>
                <Grid item xs={5} md={6} lg={3} ><Link to="/"><img src={logo} alt="" style={{padding: "0px", maxWidth: '150px', margin: 'auto'}} /></Link></Grid>
                <Hidden mdDown >
                    <Grid item lg={7} className={classes.menuBar} style={{paddingTop:"12px"}} >
                        <Link to="/" className={classes.linked}><Button fullWidth className={classes.menuBarLinkButton} >Home</Button></Link>

                        <Button aria-controls="vehicle-menu" aria-haspopup="true" onClick={handleVehicleClick} style={{color: '#cc1a17', fontWeight: 'bolder'}} >Vehicle Insurance</Button>
                        <Menu id="vehicle-menu" anchorEl={vehicleMenu} keepMounted open={Boolean(vehicleMenu)} onClose={handleVehicleClose} >
                          <MenuItem onClick={handleVehicleClose}><Link to="/two-wheeler-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Two-Wheeler Insurance</Button></Link></MenuItem>
                          <MenuItem onClick={handleVehicleClose}><Link to="/car-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Car Insurance</Button></Link></MenuItem>
                          <MenuItem onClick={handleVehicleClose}><Link to="/commercial-vehicle-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Commercial Vehicle Insurance</Button></Link></MenuItem>
                        </Menu>

                        <Button aria-controls="property-menu" aria-haspopup="true" onClick={handlePropertyClick} style={{color: '#cc1a17', fontWeight: 'bolder'}} >Property Insurance</Button>
                        <Menu id="property-menu" anchorEl={propertyMenu} keepMounted open={Boolean(propertyMenu)} onClose={handlePropertyClose} >
                          <MenuItem onClick={handlePropertyClose}><Link to="/home-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Home Insurance</Button></Link></MenuItem>
                          <MenuItem onClick={handlePropertyClose}><Link to="/office-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Office Insurance</Button></Link></MenuItem>
                          <MenuItem onClick={handlePropertyClose}><Link to="/shop-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Shop Insurance</Button></Link></MenuItem>
                        </Menu>

                        <Button aria-controls="other-menu" aria-haspopup="true" onClick={handleOtherClick} style={{color: '#cc1a17', fontWeight: 'bolder'}} >Other</Button>
                        <Menu id="other-menu" anchorEl={otherMenu} keepMounted open={Boolean(otherMenu)} onClose={handleOtherClose} >
                          <MenuItem onClick={handleOtherClose}><Link to="/credit-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Credit Insurance</Button></Link></MenuItem>
                          <MenuItem onClick={handleOtherClose}><Link to="/liability-insurance" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Liability Insurance</Button></Link></MenuItem>
                          <MenuItem onClick={handleOtherClose}><Link to="/pollution-certificate" className={classes.linked}><Button fullWidth className={classes.subMenuBarLinkButton} >Pollution Certificate</Button></Link></MenuItem>
                        </Menu>
                    </Grid>
                </Hidden>
                <Grid item xs={5} lg={2} style={{textAlign:'right', paddingTop:"10px"}}>
                    <Button fullWidth className={classes.menuBarLinkButton} startIcon={<CallIcon style={{ fontSize: 30 }} className={classes.callIcon} />}><Typography style={{"fontSize":"1.15rem", "fontWeight":"bolder"}}><a href="tel:+919978904008" target="_blank" rel="noreferrer" style={{color:"#cc1a17"}}>9978904008</a></Typography></Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Header;


