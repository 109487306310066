import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import TwoWheelerIcon from '@material-ui/icons/TwoWheeler';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SecurityIcon from '@material-ui/icons/Security';
import PolicyIcon from '@material-ui/icons/Policy';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import StorefrontIcon from '@material-ui/icons/Storefront';
import MoneyIcon from '@material-ui/icons/Money';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import family from '../static/images/family.jpeg';

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1180px', margin: "auto", padding: "0 15px"},
    heroWelcome: {fontSize: '1.2rem', fontWeight: 'bolder', color: 'white'},
    heroContent:{
        [theme.breakpoints.down('sm')]: {marginTop:"-30%"},
        [theme.breakpoints.up('md')]: {marginTop:"-22%"},
        [theme.breakpoints.up('lg')]: {marginTop:"-35%"},
        [theme.breakpoints.up('xl')]: {marginTop:"-40%"}
    },
    heroText:{fontSize: '3rem', fontWeight:'bolder', color: "white",
        [theme.breakpoints.down('sm')]: {fontSize: '1.5rem'},
        [theme.breakpoints.up('lg')]: {fontSize: '2.5rem'},
        [theme.breakpoints.up('xl')]: {fontSize: '3rem'}
    },
    heroCaption:{fontSize: '1rem', color: "#cbb4b4", lineHeight:'2rem',
        [theme.breakpoints.down('md')]: {display: 'none'},
    },
    heroBtn:{padding: "5px 30px", fontSize: "1rem", fontWeight:"bolder", marginTop:"20px",
        [theme.breakpoints.down('md')]: {padding: "5px 20px", fontSize: '0.75rem', marginTop: "10px"},
    },
    serviceBox:{
        [theme.breakpoints.down('md')]: {marginBottom: "20px"},
        [theme.breakpoints.up('md')]: {marginBottom: "50px"},
    },
    serviceBoxIcon:{background:'#f2940f', padding:"15px", borderRadius:"5px", border:"0px solid #bb4239", display:"inline-block", margin: "auto"},
    serviceBoxContent:{padding: "0 40px"},
    serviceBoxHeading:{fontWeight:"bolder", fontSize:"1.25rem"},
    serviceBoxDescription:{color:"#606060", fontSize:"14px", lineHeight:"20px", display:'none'},
    linked:{color:"#bb4239", textDecoration: 'none', fontSize:"1rem", marginTop:"10px", marginBottom:"10px", display:"block"},
    blueStrip:{background:"#2a2c5d", marginBottom:"40px", padding: "40px 0 25px 0"},
    statsFigure:{color:"white", fontSize:"2.5rem", fontWeight:"bolder", textAlign:"center"},
    statsName:{color:"white", fontSize:"1rem", textAlign:"center", fontWeight:"bold", textTransform:"uppercase", paddingBottom:"15px"},
    
    inActiveTab:{padding: '0.5rem', textAlign: 'center',
        '&:hover':{cursor: 'pointer'}
    },
    inActiveTabName:{textAlign: 'center'},
    activeTab: {padding: '0.5rem', background: "#2a2c5d", color: "white", borderRadius: "5px"},
    activeTabName:{textAlign: 'center', color: "white"},
    tabContent:{color: "#606060", lineHeight:"30px", textAlign:"justify"},
    tabDataRow:{padding: '1rem 1rem 4rem 1rem'},

    greyStrip:{background:"#e8e8f1", padding: "40px 0"}
}));

const Welcome = () => {
    const classes = useStyles();

    const [activeTab, setActiveTab] = useState(1);

    const renderAdvantageDescriptionTab = (activeTab) => {
        if(activeTab === 1){ return <Grid item xs={12} ><Typography className={classes.tabContent}>We treat all customers with warmth and respect and put out best efforts to understand and manage your expectations. We, with full attention, listen to you and are empathetic to the what you say. We are here not to do business but make relations with you that lasts long and we can cherish them for the life time. We consciously include and respect people from different backgrounds and deliver the benefits of their experiences to you.</Typography></Grid> }
        else if(activeTab === 2){ return <Grid item xs={12} ><Typography className={classes.tabContent}>Your trust is the most valuable thing that we are here to earn. Hence we are available to you round the clock and try to be as fair and transparent as possible. We value your feedback and your comments for our services and products. We keep our commitments and own up to our mistakes, if we ever made any. In this way, we trust each other to do a good job and give each other the due credit.</Typography></Grid> }
        else if(activeTab === 3){ return <Grid item xs={12} ><Typography className={classes.tabContent}>We are here to bring the change in how insurance domain works. We accept the challenges and lead the change in the industry. We look for the opportunity to innovate at every level and set new benchmarks for excellence. With our approach to adapt to the changes, we raise the bar and set new standards in servicing the customers. With our excellence in what we do and your satisfaction and happpiness, we take the pride and enjoy the moment together. </Typography></Grid> }
    }

    return (
        <>
            <Grid container className={classes.heroSection} >
                <img src="https://imgur.com/gywKPQC.jpeg" alt="" style={{"width":"100%"}} />
                <Grid container className={classes.page}>
                    <Grid className={classes.heroContent} >
                        <Typography className={classes.heroWelcome}>WELCOME TO</Typography>
                        <Typography className={classes.heroText}>Mahavir Insurance  <br/> and Advisor</Typography>
                        <Typography className={classes.heroCaption}>We deal in various types of insurance ranging from vehicles to properties <br/> you don't have to go anywhere else.</Typography>
                        {/* <a href="tel:+919153999993"><Button variant="contained" className={classes.heroBtn} style={{"background":"#2a2c5d", "color":"white"}}>Get a Quote</Button></a> */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={classes.page} style={{"marginTop":"50px", "marginBottom":"50px"}} >
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><TwoWheelerIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Two Wheeler Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/two-wheeler-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><LocalShippingIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Commercial Vehicle</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/commercial-vehicle-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><DriveEtaIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Car Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/car-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><HomeIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Home Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/home-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><BusinessIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Office Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/office-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><StorefrontIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Shop Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/shop-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><CreditCardIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Credit Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/credit-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} className={classes.serviceBox} >
                    <Grid container >
                        <Grid item xs={2}>
                            <Box boxShadow={3} className={classes.serviceBoxIcon}><BeachAccessIcon style={{"fontSize":"3rem", "color":"white"}} /></Box>
                        </Grid>
                        <Grid item xs={10} className={classes.serviceBoxContent}>
                            <Typography className={classes.serviceBoxHeading} >Liability Insurance</Typography>
                            <Typography className={classes.serviceBoxDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</Typography>
                            <Link to="/liability-insurance" className={classes.linked} >Read More</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={classes.blueStrip}>
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={3} lg={2}><Typography className={classes.statsFigure} ><SecurityIcon fontSize='large' /><br/></Typography><Typography className={classes.statsName} >Security</Typography></Grid>
                    <Grid item xs={12} md={3} lg={2}><Typography className={classes.statsFigure} ><PolicyIcon fontSize='large' /><br/></Typography><Typography className={classes.statsName} >Transparency</Typography></Grid>
                    <Grid item xs={12} md={3} lg={2}><Typography className={classes.statsFigure} ><SentimentVerySatisfiedIcon fontSize='large' /><br/></Typography><Typography className={classes.statsName} >Peace of Mind</Typography></Grid>
                    <Grid item xs={12} md={3} lg={2}><Typography className={classes.statsFigure} ><MoneyIcon fontSize='large' /><br/></Typography><Typography className={classes.statsName} >Best Price</Typography></Grid>
                    <Grid item xs={12} md={3} lg={2}><Typography className={classes.statsFigure} ><AssignmentIcon fontSize='large' /><br/></Typography><Typography className={classes.statsName} >Well Documented</Typography></Grid>
                    <Grid item xs={12} md={3} lg={2}><Typography className={classes.statsFigure} ><ContactPhoneIcon fontSize='large' /><br/></Typography><Typography className={classes.statsName} >Full Support</Typography></Grid>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} style={{"textAlign":"center", "marginBottom":"40px"}} ><Typography style={{"fontSize":"2rem", "color":"#f2940f", "fontWeight":"bolder"}} >Why Choose Us ?</Typography></Grid>
                <Grid container className={classes.page}>
                    <Grid xs={12} md={4} lg={6} style={{"textAlign":"center"}} ><img alt="" src={family} style={{"width":"80%", "padding":"30px", "paddingTop":"0px", "paddingBottom":"0"}} /></Grid>
                    <Grid xs={12} md={8} lg={6} >
                        <Grid container>
                            <Typography style={{"fontWeight":"bold", "fontSize":"1.90rem", "lineHeight":"35px", "paddingBottom":"30px", "paddingTop":"40px"}} >With us, no more hassles & no more worries</Typography>
                            <Grid container>
                                {activeTab === 1 ? 
                                    <Grid item xs={4} className={classes.activeTab}><Typography className={classes.activeTabName} >Customer First</Typography></Grid> 
                                    :
                                    <Grid item xs={4} className={classes.inActiveTab} onClick={() => {setActiveTab(1)}}><Typography className={classes.inActiveTabName} >Customer First</Typography></Grid>
                                }
                                {activeTab === 2 ? 
                                    <Grid item xs={4} className={classes.activeTab}><Typography className={classes.activeTabName} >Trust</Typography></Grid> 
                                    :
                                    <Grid item xs={4} className={classes.inActiveTab} onClick={() => {setActiveTab(2)}}><Typography className={classes.inActiveTabName} >Trust</Typography></Grid>
                                }
                                {activeTab === 3 ? 
                                    <Grid item xs={4} className={classes.activeTab}><Typography className={classes.activeTabName}>High Standard</Typography></Grid> 
                                    :
                                    <Grid item xs={4} className={classes.inActiveTab} onClick={() => {setActiveTab(3)}}><Typography className={classes.inActiveTabName}>High Standard</Typography></Grid>
                                }
                            </Grid>
                            <Grid container className={classes.tabDataRow}>
                                {renderAdvantageDescriptionTab(activeTab)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={classes.greyStrip}>
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6}>
                        <Typography style={{"fontSize":"2rem", "fontWeight":"bolder", "paddingTop":"50px", "paddingBottom":"10px"}} >Plan for your future <br/> and live your life now</Typography>
                        <Box component="div" style={{"backgroundColor":"#f2940f", "height":"3px", "width":"120px", "marginBottom":"35px"}} ></Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography style={{"color":"#606060", "lineHeight":"30px", "paddingRight":"15px"}} >Uncertainties in life could crop up at any moment, such as an unfortunate death or a medical emergency. These situations also include an accident or damage to your vehicle, property, etc. Bearing the financial impact of these situations can burn a hole into your pocket. You may need to dip into your savings or your family’s hard-earned money. Thus, there is a pressing need of insurance for you and your family for proper coverage and financial support against all risks linked to your life, health and property.</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{"padding":"60px 20px"}} >
                <Grid item xs={12} style={{"textAlign":"center", "marginBottom":"40px"}} ><Typography style={{"fontSize":"2rem", "color":"#f2940f", "fontWeight":"bolder"}} >Frequently Asked Questions</Typography></Grid>
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{"padding":"0 30px", paddingBottom: 15}} >
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" ><Typography className={classes.heading}>How do insurance claims work ?</Typography></AccordionSummary>
                            <AccordionDetails><Typography>An insurance claim is a request filed by a policyholder to a provider asking for compensation for a covered loss. The insurance company will then review the claim, and they can approve it and issue an eventual payout after investigating it, or they deny the claim.</Typography></AccordionDetails>
                        </Accordion>
                        <Accordion> 
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" > <Typography className={classes.heading}>What is deductible ?</Typography> </AccordionSummary>
                            <AccordionDetails> <Typography>Deductible or “excess” is the amount over and above, the claim payable. This ranges ranging from Rs 50 for two-wheelers to Rs 500 for Private Cars and Commercial Vehicles. Increase in the cubic capacity/carrying capacity of the vehicle increase this cost. In some cases the insurer may impose additional excess depending upon the age of the vehicle or if there is a high frequency of claims.</Typography></AccordionDetails>
                        </Accordion>                        
                        <Accordion> 
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" > <Typography className={classes.heading}>What is a Certificate of Insurance under Motor Vehicle Act ?</Typography> </AccordionSummary>
                            <AccordionDetails> <Typography>As per Rule 141 of Central Motor Vehicle Rules 1989, a certificate of Insurance is to be issued only in Form 51. It is only in Motor Vehicle Insurance, apart from the policy, that a separate certificate of insurance is required to be issued by insurers. This document should always be carried in the vehicle. The policy should be preserved separately at home/office.</Typography></AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={6} style={{"padding":"0 30px", paddingTop: 0}} >
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" ><Typography className={classes.heading}>What are Zero Depreciation Plans ?</Typography></AccordionSummary>
                            <AccordionDetails><Typography>Simple. In regular covers, when you claim insurance for any damage or repair, the insurance company would deduct some amount and make you pay for it. Wondered why. That’s what they call “Depreciation charges” So, you had a claim of 20,000 rupees but the insurance company paid only 15000 to the garage and you had to pay the balance. The balance 5000 is called Depreciation charges.</Typography></AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header"><Typography className={classes.heading}>What is "No Claim Bonus" ?</Typography></AccordionSummary>
                            <AccordionDetails> <Typography>No Claim Bonus (NCB) is your bonus for driving or riding carefully and not making a claim. You get a discount on your premium. Starting from 20% going all the way to 50%. So be careful on the road. If any claim is made, however small, the no claim bonus is lost.</Typography></AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header"><Typography className={classes.heading}>Are there different types of Car Insurance ?</Typography></AccordionSummary>
                            <AccordionDetails> <Typography>There is no difference in insurance coverage across companies, although prices do vary. Some companies offer Zero Depreciation covers, which are usually more expensive, and offer full coverage of parts and replacement.   </Typography></AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>

            
        </>
    );
}

export default Welcome;