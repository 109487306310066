import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import pageImage from '../static/images/commercial.jpeg';

const useStyles = makeStyles(theme => ({
    page:{maxWidth: '1200px', margin: "auto", padding: '2rem 0'},
    pageName:{fontSize:'3rem', textAlign:'center', fontWeight:'bolder', padding:'3rem 0', background:'#2a2c5d', color: 'white', borderTop:"5px double #ff654c", borderBottom:"5px solid #ff654c"},
    pageText:{fontSize: '1.25rem', color:'#8d8d95', lineHeight:'2.5rem', textAlign: 'justify'}
}));

const CommercialVehicle = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h1" className={classes.pageName}>Commercial Vehicle Insurance</Typography>
            <Grid container >
                <Grid container className={classes.page}>
                    <Grid item xs={12} md={6} style={{margin:'auto', textAlign: 'center'}}><img src={pageImage} alt="" style={{maxHeight: '400px', maxWidth: "98%"}} /></Grid>
                    <Grid item xs={12} md={6} style={{padding: '2.5rem', display:'flex'}} >
                        <Typography className={classes.pageText} >Commercial Vehicle Insurance is a type of motor insurance policy that provides coverage in case of damages or losses Commercial Vehicle Insurance is a type of motor insurance policy that provides coverage in case of damages or losses  All businesses must purchase a commercial vehicle insurance policy for their motor vehicles like pick-up vans, cabs, auto-rickshaws, tractors, school buses, trucks, commercial vans, etc.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CommercialVehicle;